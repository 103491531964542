/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import withIntegrationCardContext from 'hoc/withIntegrationCardContext';
import Slider from 'react-slick';

const Segment = ({ sliderSettings }) => {
  const { img1 } = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "help/segment_debugger.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const photos = [
    {
      img: img1.childImageSharp.fluid,
      alt: 'Segment Integration',
      title: 'Integrate Segment with LiveSession',
    },
  ];
  return (
    <>
      <Slider {...sliderSettings(photos)} className="without-dots">
        <div>
          <Img fluid={photos[0].img} alt={photos[0].alt} title={photos[0].title} />
        </div>
      </Slider>
      <div className="section">
        <h2>Overview</h2>
        <p>
          Segment is a customer data platform that makes information accessible to all teams. It can
          improve the day-to-day work of marketing, product, and software engineering professionals.
          In other words, it’s a powerful app to learn about your users and make your product
          data-driven
        </p>
        <p>
          Segment works best with integrations. You can connect it to LiveSession to get more
          insights.
        </p>
      </div>
      <div className="section">
        <h2>Benefits</h2>
        <ul className="checkmarks">
          <li>Access LiveSession recordings straight from Segment</li>
          <li>Connect your favorite analytics tools</li>
          <li>Use more data to get more insights</li>
        </ul>
      </div>
      <h2>How to install</h2>
      <p>
        Copy and paste a piece of code on your website. See a detailed tutorial in our{' '}
        <a href="/help/segment-integration/">help center</a>.
      </p>
    </>
  );
};

export default withIntegrationCardContext(Segment);

Segment.propTypes = {
  sliderSettings: PropTypes.func.isRequired,
};
